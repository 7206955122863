import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import fetch from "isomorphic-fetch";

import { Layout, PropertySingle, Link, Button } from "components";

const PropertyPass = (props) => {
  const { splat } = props;
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState(null);

  const loadDataCheck = () => {
    console.log("Loading...");

    const soldUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/vaultre/v1/property?slug=${splat}&type=sold,published`;
    const urlArray = splat.split("-");
    const keyIndex = ["/", "-"]; //Number dividers
    let indexStart = 0;
    const newUrlArray = [];
    urlArray.map((value, index) => {
      if (value != "wa" && value != "lot" && value != "prop") {
        newUrlArray.push(value);
        if (!isNaN(value) && !isNaN(urlArray[index + 1])) {
          //Is a number, check if next is a number
          newUrlArray.push(keyIndex[indexStart]);
          indexStart++;
        }
      }
    });
    const searchString = newUrlArray.join("+").replace("+/+", "/").replace("+-+", "-");
    const searchVault = `${process.env.GATSBY_WP_ADDRESS}/wp-json/vaultre/v1/search/property/address?term=${searchString}`;
    const fetchArgs = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log("soldUrl:", soldUrl);
    fetch(soldUrl, fetchArgs)
      .then((res) => res.json())
      .then((res) => {
        if (res?.nodes && res.nodes[0]) {
          console.log("WP Data");
          setProperty(res.nodes[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
        // else {
        //   //Search vault data
        //   console.log("Vault Data");
        //   //console.log("searchVault:", searchVault);
        //   fetch(searchVault, fetchArgs)
        //     .then((res) => res.json())
        //     .then((res) => {
        //       if (res?.nodes && res.nodes[0]) {
        //         setProperty(res.nodes[0]);
        //         setLoading(false);
        //       } else {
        //         setLoading(false);
        //       }
        //     });
        // }
      })
      .catch((error) => {
        console.error("Error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataCheck();
  }, []);
  return (
    <Layout pageTitle={"Property | Davey Real Estate"} isPropertySingle={true}>
      {loading ? (
        <div className="loading-property">
          <div className="loadingspinner--md" />
        </div>
      ) : property ? (
        <PropertySingle location={location} property={property} />
      ) : (
        <section className="layout--small section--small" style={{ textAlign: "center" }}>
          <div>
            <h1>SORRY, WE CAN'T FIND THE PAGE YOU ARE LOOKING FOR</h1>
            <p>
              It seems we can't find what you're looking for. The page you have requested cannot be displayed. This
              could mean that the property is sold or currently not available for sale online.
            </p>
            <Link to="/">
              <Button>Back to homepage</Button>
            </Link>
          </div>
        </section>
      )}
    </Layout>
  );
};

const Property = () => {
  return (
    <Router>
      <PropertyPass path="/property/:splat" />
    </Router>
  );
};

export default Property;
